import React from 'react'
import GenericLayout from '../layouts/GenericLayout';
import PageSection from '../components/PageSection';
import Contact from '../components/Contact'

import { graphql } from 'gatsby';
import { I18n } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-i18next';
import { getContentTranslation } from '../helpers';


import content from '../content/main'

import { MAP_COORDINATES, MAPS_LINK } from '../constants';

const Kontakt = () => <I18n>
    {(t, { i18n }) => {
    const { language } = i18n
    if (typeof language === 'undefined') {
        return null
    }
        const props = getContentTranslation(content, language)
      
        return <GenericLayout>
            <PageSection>
                <header className="major">
                    {/* TODO: translation */}
                    <h1>Kontakt</h1>
                </header>
            </PageSection>
                <Contact
                    {...props}
                    coordinates={MAP_COORDINATES}
                    link={MAPS_LINK}
                    
                />

           
        </GenericLayout>
        }
    }
</I18n >
   


export default withI18next()(Kontakt)


export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;